/* stylelint-disable scss/at-mixin-argumentless-call-parentheses */
@use '@angular/material' as mat;
@use 'sass:map';

@import './styles/typography';
@import './styles/colors';
@import './styles/material/material-styles';
@import 'app/modules/navigation/components/page-title/navigation-page.component.scss-theme';
@import 'app/modules/admin/components/search-bar/search-bar.component.scss-theme';
@import 'app/shared/components/file-uploader/file-uploader.component.scss-theme';
@import 'app/shared/common/UI/common-styles';
@import 'assets/themes/default-theme';
@import 'assets/themes/dark-theme';

@include mat.core();

@mixin custom-components-theme($theme) {
  @include navigation-page-component-theme($theme);
  @include search-bar-component-theme($theme);
  @include file-uploader-component-theme($theme);
}

body.default-theme {
  background-color: mat.get-color-from-palette($default-primary, 50);
}

.default-theme {
  $background: map.get($default-theme, background);

  @include mat.all-component-themes($default-theme);
  @include custom-components-theme($default-theme);

  .mat-ink-bar,
  .slim-loading-bar-progress {
    background-color: mat.get-color-from-palette($default-accent, 500) !important;
  }

  .nav-mat-tooltip {
    --mdc-plain-tooltip-container-color: var(--global-white-color);
    --mdc-plain-tooltip-supporting-text-color: var(--global-base-black-color);

    padding: 10px 2px;
    background-color: var(--global-white-color);
    border-radius: 7px;
    box-shadow: 0 6px 15px rgb(0 0 0 / 25%);
  }

  .mat-mdc-progress-spinner circle {
    stroke: inherit;
  }

  .mat-mdc-menu-panel {
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 35%) !important;
  }
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  background-color: var(--global-white-color);
}

body {
  user-select: none;
  overflow-x: hidden;
  margin: 0;
}

// стили  для mat-menu
.mat-mdc-menu-panel.navigation-menu {
  margin-top: 4px;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}
